import {
  Destiny1Img,
  Destiny2Img,
  Destiny3Img,
  Destiny4Img,
  Destiny5Img,
  Destiny6Img,
  Destiny7Img,
  Destiny8Img,
  DestinyBannerImg,
  DestinySilverImg,
  DestinySmBanner1Img,
  DestinySmBanner2Img,
  DestinySmBanner3Img,
  DestinySmBanner4Img,
  DestinySmBanner5Img,
  DestinySmBanner6Img,
  DestinyblackImg,
  DestinyblueImg,
  DestinyredImg,
  DestinywhiteImg,
  FreedomSmBanner1Img,
  FreedomSmBanner2Img,
  FreedomSmBanner3Img,
  FreedomSmBanner4Img,
  FreedomSmBanner5Img,
  FreedomSmBanner6Img,
  PhantomSmBanner1Img,
  PhantomSmBanner2Img,
  PhantomSmBanner3Img,
  PhantomSmBanner4Img,
  PhantomSmBanner5Img,
  PhantomSmBanner6Img,
  PhantomgreyImg,
  bannerMainImg,
  desDesign1Img,
  desDesign2Img,
  desDesign3Img,
  desPerformance1Img,
  desPerformance2Img,
  desPerformance3Img,
  destinySavingsImg,
  feedomProSavingsImg,
  freedom1Img,
  freedom2Img,
  freedom3Img,
  freedom4Img,
  freedomBannerImg,
  freedomFeaturesImg,
  phantom1Img,
  phantom2Img,
  phantom3Img,
  phantom4Img,
  phantom5Img,
  phantom6Img,
  phantom7Img,
  phantom8Img,
  phantomBannerImg,
  phantomFeaturesImg,
  phantomSavingsImg,
} from "../Icons";

export const Destiny = {
  banner: DestinyBannerImg,
  savingsImg: destinySavingsImg,
  featuresImg: bannerMainImg,
  carousel: [
    Destiny1Img,
    Destiny2Img,
    Destiny3Img,
    Destiny4Img,
    Destiny5Img,
    Destiny6Img,
    Destiny7Img,
    Destiny8Img,
  ],
  bannerSmallImgs: [
    DestinySmBanner1Img,
    DestinySmBanner2Img,
    DestinySmBanner3Img,
    DestinySmBanner4Img,
    DestinySmBanner5Img,
    DestinySmBanner6Img,
  ],
  colors: [
    { color: "Blue", colorImg: DestinyblueImg, scootyImg: Destiny4Img },
    { color: "Black", colorImg: DestinyblackImg, scootyImg: Destiny6Img },
    { color: "White", colorImg: DestinywhiteImg, scootyImg: Destiny7Img },
    { color: "Red", colorImg: DestinyredImg, scootyImg: Destiny3Img },
    { color: "Silver", colorImg: DestinySilverImg, scootyImg: Destiny5Img },
  ],
  performance: [
    { img: desPerformance1Img, info: "250W" },
    { img: desPerformance2Img, info: "25km/Hour" },
    { img: desPerformance3Img, info: "2 Person" },
  ],
  design: [
    { img: desDesign1Img, info: "Comfortable Seat" },
    { img: desDesign2Img, info: "Front LED Lights" },
    { img: desDesign3Img, info: "Coloured Display" },
  ],
  tech: [
    { img: Destiny2Img, info: "Keyless Entry" },
    { img: Destiny7Img, info: "USB Mobile Charging" },
    { img: Destiny8Img, info: "Anti theft Alarm" },
  ],
};

export const Phantom = {
  banner: phantomBannerImg,
  savingsImg: phantomSavingsImg,
  featuresImg: phantomFeaturesImg,
  carousel: [
    phantom1Img,
    phantom2Img,
    phantom3Img,
    phantom4Img,
    phantom5Img,
    phantom6Img,
    phantom7Img,
  ],
  bannerSmallImgs: [
    PhantomSmBanner1Img,
    PhantomSmBanner2Img,
    PhantomSmBanner3Img,
    PhantomSmBanner4Img,
    PhantomSmBanner5Img,
    PhantomSmBanner6Img,
  ],
  colors: [
    { color: "Black", colorImg: DestinyblackImg, scootyImg: phantom8Img },
    { color: "White", colorImg: DestinywhiteImg, scootyImg: phantom2Img },
    { color: "Grey", colorImg: PhantomgreyImg, scootyImg: phantom6Img },
    { color: "Silver", colorImg: DestinySilverImg, scootyImg: phantom7Img },
  ],
  performance: [
    { img: desPerformance1Img, info: "250W" },
    { img: desPerformance2Img, info: "25km/Hour" },
    { img: desPerformance3Img, info: "2 Person" },
  ],
  design: [
    { img: desDesign1Img, info: "Comfortable Seat" },
    { img: desDesign2Img, info: "Front LED Lights" },
    { img: desDesign3Img, info: "Coloured Display" },
  ],
  tech: [
    { img: phantom3Img, info: "Keyless Entry" },
    { img: phantom4Img, info: "USB Mobile Charging" },
    { img: phantom5Img, info: "Anti theft Alarm" },
  ],
};

export const Freedom_Pro = {
  banner: freedomBannerImg,
  savingsImg: feedomProSavingsImg,
  featuresImg: freedomFeaturesImg,
  carousel: [freedom1Img, freedom2Img, freedom3Img, freedom4Img],
  bannerSmallImgs: [
    FreedomSmBanner1Img,
    FreedomSmBanner2Img,
    FreedomSmBanner3Img,
    FreedomSmBanner4Img,
    FreedomSmBanner5Img,
    FreedomSmBanner6Img,
  ],
  colors: [
    { color: "Black", colorImg: DestinyblackImg, scootyImg: freedom1Img },
    { color: "White", colorImg: DestinywhiteImg, scootyImg: freedom4Img },
    { color: "Grey", colorImg: PhantomgreyImg, scootyImg: freedom2Img },
    { color: "Blue", colorImg: DestinyblueImg, scootyImg: freedom3Img },
  ],
  performance: [
    { img: desPerformance1Img, info: "250W" },
    { img: desPerformance2Img, info: "25km/Hour" },
    { img: desPerformance3Img, info: "2 Person" },
  ],
  design: [
    { img: desDesign1Img, info: "Comfortable Seat" },
    { img: desDesign2Img, info: "Front LED Lights" },
    { img: desDesign3Img, info: "Coloured Display" },
  ],
  tech: [
    { img: freedom3Img, info: "Keyless Entry" },
    { img: freedom2Img, info: "USB Mobile Charging" },
    { img: freedom1Img, info: "Anti theft Alarm" },
  ],
};
