import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { defaultUser, reviewStar } from "../../Icons";
import MotionViewport from "../animate/MotionViewport";
import { varFade } from "../animate/variants/fade";

const ReviewsCard = ({ title, reviews }) => {
  return (
    <>
      <div className="reviewCardOuter">
        <Box className="reviewsCards" sx={{ pt: 3, minHeight: "60vh" }}>
          <MotionViewport variants={varFade().in}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                px: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  margin: "0 auto",
                }}
              >
                <Avatar
                  alt="Default user"
                  src={defaultUser}
                  style={{ width: 100, height: 100 }}
                />
              </Box>

              {/* name */}
              <Typography
                variant="h5"
                component="div"
                color="#fff"
                textAlign="center"
                sx={{ my: 2 }}
              >
                {title}
              </Typography>
              <Box sx={{ width: 120, margin: "0 auto" }}>
                <img src={reviewStar} alt="reviews star" width="100%" />
              </Box>
              <Typography
                variant="body1"
                component="div"
                color="#fff"
                textAlign="center"
                sx={{ my: 2, fontSize: "13px", textAlign: "justify" }}
              >
                {reviews}
              </Typography>
            </Box>
          </MotionViewport>
        </Box>
      </div>
    </>
  );
};

export default ReviewsCard;
