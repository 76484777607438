import { Box, Typography } from "@mui/material";
import React from "react";
import BannerSubtitle from "./BannerSubtitle";
import Mount from "./Mount";
import { m } from "framer-motion";
import { varFade } from "./animate/variants/fade";
import MotionContainer from "./animate/MotionContainer";

// ################################################################
// use this banner component to display banner at top of every page
// ------------------ comment added by ankur-----------------------
// ################################################################

const Banner = ({
  img,
  title,
  alt,
  subTitle,
  imgStyle,
  isNeon = false,
  height,
}) => {
  return (
    <Box
      component={MotionContainer}
      sx={{
        position: "relative",
        width: "100%",
        height: height ? height : "80vh",
        mb: 10,
      }}
    >
      <img
        src={img}
        alt={alt}
        style={{
          width: "100%",
          height: "inherit",
          ...imgStyle,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <BannerTitle title={title ? title : ""} />
        <Mount visible={isNeon}>
          <BannerSubtitle
            subTitle={subTitle ? subTitle : ""}
            style={{
              paddingTop: "12px",
            }}
          />
        </Mount>
        <Mount visible={!isNeon}>
          <m.div variants={varFade().inUp}>
            <Typography
              variant="h4"
              sx={{
                color: "white",
                fontSize: "64px",
                fontWeight: "bold",
              }}
            >
              {subTitle ? subTitle : ""}
            </Typography>
          </m.div>
        </Mount>
      </Box>
    </Box>
  );
};

export default Banner;
function BannerTitle({ title }) {
  return (
    <m.div variants={varFade().inDown}>
      <Typography
        sx={{
          color: "#92c83e",
          fontSize: "24px",
          textTransform: "uppercase",
        }}
      >
        {title}
      </Typography>
    </m.div>
  );
}
