import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Banner from "./Banner";
import { gradientBg } from "../Icons";
import { Container } from "@mui/material";

const tabItems = [
  {
    label: "What is an Urban eBike?",
    content:
      "An Urban eBike is a transportation vehicle powered by an electric motor. It typically has a handlebar, a deck, and a battery that powers the motor.",
  },
  {
    label: "How does an Urban eBike work? ",
    content:
      "Urban eBikes work by using a battery to power an electric motor, which turns the wheels and propels the scooter forward. The rider controls the speed and direction using a throttle and brake.",
  },
  {
    label: "How fast can Urban eBikes go?",
    content:
      "The speed of Urban eBikes can vary widely, but most consumer-grade Urban eBikes have a top speed ranging from 25 Km/hour).",
  },
  {
    label: "What is the range of an electric scooter?",
    content:
      "The range of Urban eBikes depends on factors like battery capacity and rider weight.Generally, most Urban eBikes can travel 80-100 Km on a single charge.",
  },
  {
    label: "How long does it take to charge an electric scooter?",
    content:
      "Charging times vary, but it usually takes around 4 to 5 hours to fully charge an electric scooter's battery.",
  },
  {
    label: "Do I need a license to ride an Urban eBike?",
    content:
      "In many places, you don't need a driver's license to ride an electric scooter, but age and helmet requirements may apply. Laws vary by location, so check your local regulations.",
  },
  {
    label: "Are Urban eBikes legal on public roads and sidewalks?",
    content:
      "Regulations regarding where you can ride Urban eBikes vary by location. Some areas allow them on roads and bike lanes, while others restrict them to sidewalks. It's important to know and follow local laws.",
  },
  {
    label: "Are Urban eBikes safe?",
    content:
      "Urban eBikes can be safe when used responsibly. Wearing a helmet, following traffic rules, and maintaining the scooter properly can help enhance safety.",
  },
  {
    label: "How do I maintain an electric scooter?",
    content:
      "Maintenance includes regularly checking tire pressure, keeping the battery charged,lubricating moving parts, and inspecting brakes. Refer to your scooter's manual for specific maintenance guidelines.",
  },
  {
    label: "Are Urban eBikes eco-friendly?",
    content:
      "Urban eBikes produce no direct emissions and are considered more environment friendly than gasoline-powered vehicles. However, the environmental impact can vary depending on the source of electricity used for charging.",
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, textAlign: "left" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Faqs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Banner
        img={gradientBg}
        title="Faq's"
        subTitle="Frequently Asked Questions"
      />
      <Container sx={{ mb: 10 }}>
        <Typography variant="h6">
          We are here to answer all your questions. You can browse the
          frequently asked questions and if you don´t find the answer you are
          looking for, please do not hesitate to e-mail us at info@urban
          ebikes.in."
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: "70vh",
            mt: 5,
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            {tabItems.map((item, index) => (
              <Tab
                key={index}
                label={item.label}
                {...a11yProps(index)}
                sx={{ textAlign: "right" }}
              />
            ))}
          </Tabs>
          {tabItems.map((item, index) => (
            <TabPanel key={index} value={value} index={index}>
              <Container>
                <Typography
                  variant="h2"
                  sx={{ mt: 5, color: "#04184b", fontWeight: "500" }}
                >
                  {item.label}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "25px", mt: 4, textAlign: "justify" }}
                >
                  {item.content}
                </Typography>
              </Container>
            </TabPanel>
          ))}
        </Box>
      </Container>
    </>
  );
}
