import React from "react";
import { m } from "framer-motion";
import { varZoom } from "./animate/variants/zoom";

const BannerSubtitle = ({
  subTitle = "Leading the electric mobility revolution",
  style,
}) => {
  const words = subTitle.split(" ");

  return (
    <m.div variants={varZoom().inUp}>
      <div
        className="sign"
        style={{
          ...style,
        }}
      >
        <span className="fast-flicker" id="word1">
          {words[0]}
        </span>
        <span id="word2" style={{ marginLeft: "20px" }}>
          {words.slice(1, -1).join(" ")} {/* Join words in between */}{" "}
        </span>
        <span className="flicker" id="word3">
          {words[words.length - 1]}
        </span>
      </div>
    </m.div>
  );
};

export default BannerSubtitle;

//   const separateFromSpace = (
//     subTitle = "Leading the electric mobility revolution"
//   ) => {
//     // Split the subTitle into words
//     var words = subTitle.split(" ");

//     // Get references to the span elements by their IDs
//     var word1Element = document.getElementById("word1");
//     var word2Element = document.getElementById("word2");
//     var word3Element = document.getElementById("word3");

//     // Set the content of each span element to the corresponding word
//     word1Element.textContent = words[0];
//     word2Element.textContent = words.slice(1, -1).join(" "); // Join words in between
//     word3Element.textContent = words[words.length - 1];
//   };
