import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Mount from "../Mount";
import { styled } from "@mui/material/styles";
import useResponsive from "../../hooks/useResponsive";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const TechAndDesign = ({ performance, design, tech }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const isMobile = useResponsive("down", "sm");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(isMobile ? 20 : 35),
      fontStyle: "italic",
      // marginRight: theme.spacing(1),
      // padding: 0,
      color: "black",
      "&.Mui-selected": {
        color: "#4163bb",
        fontWeight: "bold",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );
  return (
    <Grid container sx={{ my: 6 }}>
      <Grid item md={12}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="specs tabs example"
          // orientation="vertical"
          scrollButtons={"auto  "}
          variant="scrollable"
          // allowScrollButtonsMobile={isMobile}
        >
          <StyledTab label="Performance" {...a11yProps(0)} />
          <StyledTab label="Design" {...a11yProps(1)} />
          <StyledTab label="Digital Tech" {...a11yProps(2)} />
        </StyledTabs>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            {/* <Title title="Performance" /> */}
            <Grid container sx={{ mt: 5 }} spacing={isMobile ? 0 : 2}>
              {performance.map((item, index) => {
                return (
                  <TabsData
                    parent={index}
                    data={item}
                    title={["Motor Power", "Top Speed", "Seating Capacity"]}
                  />
                );
              })}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {/* <Title title="Design" /> */}
            <Grid container sx={{ mt: 5 }} spacing={isMobile ? 0 : 2}>
              {design.map((item, index) => {
                return (
                  <TabsData
                    parent={index}
                    data={item}
                    title={["Seat", "Lights", "Dashboard"]}
                  />
                );
              })}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            {/* <Title title="Digital Tech" /> */}
            <Grid container sx={{ mt: 5 }} spacing={isMobile ? 0 : 2}>
              {tech.map((item, index) => {
                return (
                  <TabsData
                    parent={index}
                    data={item}
                    title={["Feature", "Feature", "Feature"]}
                  />
                );
              })}
            </Grid>
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};

export default TechAndDesign;

// eslint-disable-next-line no-unused-vars
const Title = ({ title }) => {
  return (
    <Typography
      sx={{
        fontSize: "50px",
        color: "#04184b",
        fontWeight: "900",
        // mt: 3,
      }}
    >
      {title}
    </Typography>
  );
};

const TabsData = ({ parent, data, title }) => {
  const t = title.filter((t, index) => {
    return parent === index;
  });

  return (
    <Grid item md={4}>
      <img
        src={data.img}
        alt="performance"
        width="100%"
        style={{ borderRadius: "8px" }}
      />
      <Mount visible={title.length > 0}>
        <Typography
          sx={{
            my: 2,
            color: "#04184b",
            fontWeight: "900",
            fontSize: "24px",
          }}
        >
          {t}
        </Typography>
      </Mount>
      <Typography
        sx={{
          my: 2,
          fontSize: "18px",
          opacity: "0.7",
          fontWeight: "900",
        }}
      >
        {data.info}
      </Typography>
    </Grid>
  );
};
