import { Grid } from "@mui/material";
import React from "react";
import Banner from "./Banner";
import ScootyColors from "./ScootyColors";
import SavingsSection from "./Landing/SavingsSection";
import SlickCarousel from "./SlickCarousel";
import TechAndDesign from "./Landing/TechAndDesign";
import ImagesSection from "./Landing/ImagesSection";

const SingleScootyPageView = ({ scooty, view, isBig }) => {
  return (
    <Grid container>
      <Banner img={scooty.banner} height="100%" />
      <SlickCarousel heading="Best from the rest" images={scooty.carousel} />
      <ImagesSection
        img={scooty.featuresImg}
        smallImages={scooty.bannerSmallImgs}
        isBig={isBig}
      />
      <TechAndDesign
        performance={scooty.performance}
        design={scooty.design}
        tech={scooty.tech}
      />
      <ScootyColors
        colors={scooty.colors}
        images={scooty.carousel}
        view={view}
      />
      <SavingsSection img={scooty.savingsImg} />
    </Grid>
  );
};

export default SingleScootyPageView;
