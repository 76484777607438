import React, { useEffect } from "react";
import VideoSection from "../components/Landing/VideoSection";
import ScootyAttributes from "../components/Landing/ScootyAttributes";
import ImagesSection from "../components/Landing/ImagesSection";
import FeaturesShowcase from "../components/Landing/FeaturesShowcase";
import Reviews from "../components/Landing/Reviews";
import OfficeLocation from "../components/Landing/OfficeLocation";
import SavingsSection from "../components/Landing/SavingsSection";
import { bannerMainImg, savingsImg } from "../Icons";
import { Destiny } from "../utils/DataUtil";
import useResponsive from "../hooks/useResponsive";

const Landing = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const isBig = useResponsive("up", "sm");

  return (
    <div>
      <VideoSection />
      <ScootyAttributes />
      <ImagesSection
        img={bannerMainImg}
        view="destinyPlus"
        smallImages={Destiny.bannerSmallImgs}
        isBig={isBig}
      />
      <FeaturesShowcase isBig={isBig} />
      <OfficeLocation />
      <SavingsSection img={savingsImg} />
      <Reviews />
    </div>
  );
};

export default Landing;
