import React, { useEffect } from "react";
import useResponsive from "../hooks/useResponsive";
import SingleScootyPageView from "../components/SingleScootyPageView";
import { Freedom_Pro } from "../utils/DataUtil";
import { Box } from "@mui/material";

const FreedomProView = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const isBig = useResponsive("up", "sm");
  return (
    <Box>
      <SingleScootyPageView
        scooty={Freedom_Pro}
        view="freedomPro"
        isBig={isBig}
      />
    </Box>
  );
};

export default FreedomProView;
