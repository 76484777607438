import { Container } from "@mui/material";
import React from "react";
import Banner from "./Banner";
import { gradientBg } from "../Icons";

const TermCondition = () => {
  return (
    <>
      <Banner
        img={gradientBg}
        title="Term & Condition"
        subTitle="Urban eBikes"
      />
      <Container sx={{ mb: 10 }}>
        <div>
          <p>
            <h2>1. Acceptance of Terms</h2>
            By accessing and using the Urban e-Bikes Electric Vehicle Website
            (the "Website"), you agree to comply with and be bound by these
            Terms and Conditions ("T&C"). If you do not agree with these T&C,
            please refrain from using the Website.
          </p>
          <p>
            <h2>2. Changes to Terms</h2>
            Urban e-Bikes reserves the right to modify, amend, or update these
            T&Cs at any time without prior notice. It is your responsibility to
            review the T&C periodically for changes. Continued use of the
            Website constitutes acceptance of any modifications.
          </p>
          <h2>3. Use of the Website</h2>
          <p>
            3.1. You must be of legal age or have the necessary consent to use
            this Website.
          </p>
          <p>
            3.2. You agree to use the Website only for lawful purposes and in a
            manner that does not violate any applicable laws or regulations.
          </p>
          <p>
            3.3. Urban e-Bikes may restrict or terminate your access to the
            Website if you violate these T&Cs or engage in any unlawful or
            harmful activities.
          </p>
          <h2>4. Intellectual Property</h2>
          <p>
            4.1. The content on this Website, including but not limited to text,
            images, logos, trademarks, and software, is the property of Urban
            e-Bikes and is protected by copyright and other intellectual
            property laws.
          </p>
          <p>
            4.2. You may not reproduce, distribute, modify, or display any part
            of the Website without prior written consent from Urban e-Bikes.
          </p>
          <h2>5. Privacy</h2>
          <p>
            Urban e-Bikes collects and processes personal data as described in
            its Privacy Policy, which is an integral part of these T&Cs.
          </p>
          <h2>6. Third-Party Links</h2>
          <p>
            The Website may contain links to third-party websites for your
            convenience. Urban e-Bikes is not responsible for the content,
            accuracy, or availability of these external sites. Visiting
            third-party sites linked from the Website is at your own risk.
          </p>
          <h2>7. Limitation of Liability</h2>
          <p>
            7.1. Urban e-Bikes makes no representations or warranties about the
            accuracy or completeness of the content on the Website. Your use of
            the Website is at your own risk.
          </p>
          <p>
            7.2. Urban e-Bikes is not liable for any direct, indirect,
            incidental, special, consequential, or punitive damages arising from
            the use or inability to use the Website.
          </p>
          <h2>8. Indemnification</h2>
          <p>
            You agree to indemnify and hold harmless Urban e-Bikes and its
            affiliates, officers, employees, and agents from any claims,
            damages, liabilities, or expenses arising out of your use of the
            Website or any violation of these T&Cs.
          </p>
          <h2>9. Governing Law</h2>
          <p>
            These T&Cs shall be governed by and construed in accordance with the
            laws of India. Any disputes arising from these T&Cs or your use of
            the Website shall be subject to the exclusive jurisdiction of the
            courts in Delhi/ Punjab.
          </p>
          <h2>10. Contact Information</h2>
          <p>
            If you have any questions or concerns about these T&Cs, please
            contact Urban e-Bikes at info@urbanebikes.in.
          </p>
          <p>
            **By using the Urban e-Bikes Electric Vehicle Website, you
            acknowledge that you have read, understood, and agree to abide by
            these Terms and Conditions.
          </p>
        </div>
      </Container>
    </>
  );
};

export default TermCondition;
