/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useResponsive from "../hooks/useResponsive";

const SlickCarousel = ({ images }) => {
  const carouselRef = useRef(null);
  const isMobile = useResponsive("down", "sm");

  return (
    <Box
      className="carousel-container"
      sx={{
        display: "flex",
        flexDirection: "column", // Make it a column layout
        alignItems: "flex-start",
        py: 10,
        px: isMobile ? 3 : 5,
      }}
    >
      <Typography component="div" variant={isMobile ? "h5" : "h2"} color="#fff">
        The Best Daily Commute Scooter <br />
        for Indians
      </Typography>
      {isMobile ? (
        <Carousel
          ref={carouselRef}
          showStatus={false}
          showThumbs={false}
          centerMode={true}
          // centerSlidePercentage={100}
          emulateTouch={true}
          infiniteLoop={true}
        >
          {images.map((image, index) => {
            return (
              <Grid
                container
                key={index}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid
                  item
                  md={12}
                  xs={0}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "0 15px", // Add spacing between images
                  }}
                >
                  <img
                    src={image}
                    style={{
                      maxWidth: "100%",
                      width: "100%", // Adjust the width for smaller size
                      transition: "width 0.3s ease",
                    }}
                    alt="image"
                  />
                </Grid>
              </Grid>
            );
          })}
        </Carousel>
      ) : (
        <Carousel
          ref={carouselRef}
          showStatus={false}
          showThumbs={false}
          centerMode={true}
          centerSlidePercentage={100}
          emulateTouch={true}
          infiniteLoop={true}
        >
          {images.map((image, index) => {
            const prevIndex = (index - 1 + images.length) % images.length;
            const nextIndex = (index + 1) % images.length;

            return (
              <Grid
                container
                key={index}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid
                  item
                  md={3.5}
                  xs={0}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "0 15px", // Add spacing between images
                  }}
                >
                  <img
                    src={images[prevIndex]}
                    alt={`banner${prevIndex}`}
                    style={{
                      maxWidth: "100%",
                      width: "400px", // Adjust the width for smaller size
                      transition: "width 0.3s ease",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={5}
                  xs={0}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={image}
                    alt={`banner${index}`}
                    style={{
                      maxWidth: "100%",
                      width: "800px", // Main image size
                      transition: "width 0.3s ease",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={3.5}
                  xs={0}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "0 15px", // Add spacing between images
                  }}
                >
                  <img
                    src={images[nextIndex]}
                    alt={`banner${nextIndex}`}
                    style={{
                      maxWidth: "100%",
                      width: "400px", // Adjust the width for smaller size
                      transition: "width 0.3s ease",
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Carousel>
      )}
    </Box>
  );
};

export default SlickCarousel;
