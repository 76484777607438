export const FIRSTNAV = [
  { route: "Destiny +", to: "/scooty/destiny-plus" },
  { route: "Phantom", to: "/scooty/phantom" },
  { route: "Freedom Pro", to: "/scooty/freedom-pro" },
];

export const SECNAV = [
  { route: "About Us", to: "/about-us" },
  // { route: "Experience center", to: "/experience-center" },
  // { route: "Order Now", to: "/order" },
];
