import { Icon } from "@iconify/react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { whatsapp } from "../Icons";
let message;
const ContactUs = () => {
  const location = useLocation();

  message = "Hello! Urban eBikes!"; // Default message for unknown routes

  // Determine the message based on the current route
  if (location.pathname === "/scooty/destiny-plus") {
    message =
      "Hello! Urban eBikes! I want to enquire about destiny plus model. Kindly send the details for the former..";
  } else if (location.pathname === "/scooty/phantom") {
    message =
      "Hello! Urban eBikes! I want to enquire about phantom model. Kindly send the details for the former..";
  }

  const openWhatsAppChat = () => {
    const phoneNumber = "9530612345"; // Replace with the phone number you want to open the chat with

    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;

    // Open the WhatsApp chat in a new window or tab
    window.open(whatsappURL, "_blank");
  };
  return (
    <>
      <Container sx={{ pt: 10 }}>
        <Grid container>
          <Grid item md={6} xs={12} sx={{ pt: 3 }}>
            <stack>
              <Typography
                variant="span"
                className="contactus_heading "
                sx={{ mb: 8 }}
              >
                Contact Us
              </Typography>
              <p className="contactus_subheading">
                Have questions for us or want to reach out?
              </p>
              <p className="contactus_subheading">
                We'd love to hear from you.
              </p>
            </stack>
            <stack>
              <Typography
                fontWeight="bold"
                fontSize="22px"
                sx={{ mt: 10, mb: 2 }}
              >
                Sales and Service related Support
              </Typography>
              <Typography>
                <Icon icon="ic:baseline-call" sx={{ fontSize: "20xpx" }} />

                <span
                  style={{
                    color: "#000",
                    fontSize: "16px",
                    marginLeft: "10px",
                  }}
                >
                  +91-9530612345
                </span>
              </Typography>
              <Typography>
                <Icon icon="ic:baseline-call" sx={{ fontSize: "20px" }} />

                <span
                  style={{
                    color: "#000",
                    fontSize: "16px",
                    marginLeft: "10px",
                  }}
                >
                  +91-7009400885
                </span>
              </Typography>
              <Typography>
                <Icon icon="ic:baseline-call" sx={{ fontSize: "20px" }} />

                <span
                  style={{
                    color: "#000",
                    fontSize: "16px",
                    marginLeft: "10px",
                  }}
                >
                  +91-7009400881
                </span>
              </Typography>
              <Typography sx={{ mt: 3 }}>
                <Icon icon="line-md:email-twotone" sx={{ fontSize: "22px" }} />

                <span
                  style={{
                    color: "green",
                    fontSize: "20px",
                    marginLeft: "10px",
                  }}
                >
                  www.@urban ebikes.in
                </span>
              </Typography>
            </stack>
          </Grid>
          <Grid item md={6} xs={12} className="contact-us">
            <Box className="contact-container">
              <img src={whatsapp} alt="whats app logo" width="150px" />
              <Typography
                variant="body2"
                sx={{ fontSize: "20px", lineHeight: "26px", mb: 5, mt: 5 }}
              >
                Chat on WhatsApp with 9530612345
              </Typography>
              <Button onClick={openWhatsAppChat} className="whatsapp-link">
                Continue to Chat
              </Button>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "16px",
                  lineHeight: "26px",
                  color: "#5E5E5E",
                  mt: 3,
                }}
              >
                Click the button below to start or continue the chat with our
                team
              </Typography>
              <Typography sx={{ mt: 10, fontSize: "20px" }}>
                Don't have WhatsApp yet?
              </Typography>
              <Link
                to="https://www.whatsapp.com/download"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                Download
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ContactUs;
