import { Grid } from "@mui/material";
import React from "react";
import LocationComponent from "./LocationComponent";
import { locBankGroundImg, locScootyImg } from "../../Icons";

const OfficeLocation = () => {
  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <LocationComponent
          img={locBankGroundImg}
          firstSubText="Urban Space"
          maintext="Explore the future with us"
          officeAddres={`Urban Electric Mobility Pvt Ltd KH.NO. 154/901 
          Industrial Area Pooth Khurd Delhi (110039)`}
          subImg={locScootyImg}
          email="info@urbanebikes.in"
          tel="+91-9530612345"
        />
      </Grid>
    </Grid>
  );
};

export default OfficeLocation;
