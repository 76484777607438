import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SharedLayout from "./View/SharedLayout";
import Landing from "./View/Landing";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AboutUs from "./View/AboutUs";
import TermCondition from "./components/TermCondition";
import Faqs from "./components/Faqs";
import ContactUs from "./components/ContactUs";
import DestinyPlusView from "./View/DestinyPlusView";
import PhantomView from "./View/PhantomView";
import FreedomProView from "./View/FreedomProView";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-conditions" element={<TermCondition />} />
        <Route path="faqs" element={<Faqs />} />

        <Route path="/" element={<SharedLayout />}>
          <Route index element={<Landing />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="scooty/destiny-plus" element={<DestinyPlusView />} />
          <Route path="scooty/freedom-pro" element={<FreedomProView />} />
          <Route path="scooty/phantom" element={<PhantomView />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
