import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  gearIcon,
  mobileIcon,
  networkIcon,
  transmissionIcon,
} from "../../Icons";
import useResponsive from "../../hooks/useResponsive";
import Mount from "../Mount";
import MotionViewport from "../animate/MotionViewport";
import { varFade } from "../animate/variants/fade";

const ScootyAttributes = () => {
  const isMobile = useResponsive("down", "sm");
  return (
    <Grid
      container
      spacing={4}
      sx={{
        my: 10,
        px: 6,
      }}
    >
      {data &&
        data.length > 0 &&
        data.map((item, index) => {
          return (
            <Grid item md={3} xs={12} className="attributes">
              <MotionViewport variants={varFade().in}>
                <Mount visible={!isMobile}>
                  <div className="attributes-image">
                    <img src={item.img} alt="mobile" />
                  </div>
                </Mount>
                <div>
                  <Typography className="attributes-heading">
                    {item.title}
                  </Typography>
                  <Typography className="attributes-description">
                    {item.description}
                  </Typography>
                </div>
              </MotionViewport>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default ScootyAttributes;

const data = [
  {
    img: gearIcon,
    title: `ROBUST BUILD`,
    description: `Seamless steel unibody that is built to last.`,
  },
  {
    img: mobileIcon,
    title: `CONNECTED`,
    description: ` Future ready with Bluetooth, tamper alert, geo-fencing & OTA updates
    – all via the Chetak app!`,
  },
  {
    img: transmissionIcon,
    title: `ALL NEW STYLE`,
    description: `All new colour LCD console, body-coloured mirrors to match three new
    vibrant colours.`,
  },
  {
    img: networkIcon,
    title: `NETWORK`,
    description: `Available in 80+ Cities Ex-Showroom Price (Bengaluru): ₹ 1 30 000/-`,
  },
];
