import { Grid } from "@mui/material";
import React from "react";
import Mount from "../Mount";

const ImagesSection = ({ img, smallImages, isBig }) => {
  return (
    <>
      <Mount visible={isBig}>
        <Grid container>
          <img src={img} alt="banner1" width="100%" />
        </Grid>
      </Mount>
      <Mount visible={!isBig}>
        <Grid container>
          {smallImages.map((img) => {
            return <img src={img} alt="smimg1" width="100%" />;
          })}
        </Grid>
      </Mount>
    </>
  );
};

export default ImagesSection;
