import {
  Box,
  Button,
  Drawer,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";

import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { FIRSTNAV } from "../utils/constants";
import { useNavigate } from "react-router-dom";
const SideDrawer = () => {
  const navigate = useNavigate();
  const theme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background:
              "linear-gradient(176.37deg, #0c1024 5.57%, #0c1024 16.82%, #08131f 51.24%, #0c1024 99.86%)",
            color: "#fff",
          },
        },
      },
    },
  });

  const [state, setState] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };
  return (
    <ThemeProvider theme={theme}>
      <div style={{ margin: "0 0.5rem" }}>
        <React.Fragment key={"right"}>
          <Icon
            icon="pajamas:hamburger"
            onClick={() => setState(true)}
            className="just-hover"
          />
          <Drawer
            anchor={"right"}
            open={state}
            className="position-relative"
            onClose={() => setState(false)}
          >
            <Icon
              icon="ep:close-bold"
              onClick={() => setState(false)}
              className="sidebar-close-icon"
            />

            <Box
              sx={{
                width: { md: 400, sm: "auto", xs: "auto", height: "100vh" },
                minWidth: "260px",
              }}
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
              className="flex-col-hs-v-between"
            >
              <Grid sx={{ py: 8, px: 3 }}>
                <Typography
                  sx={{
                    fontSize: "26px",
                    color: "#828A98",
                    fontWeight: "900",
                    mb: 3,
                  }}
                >
                  PRODUCT
                </Typography>
                {FIRSTNAV.map((link) => {
                  return (
                    <div
                      className="sidebar-product-link"
                      onClick={() => navigate(link.to)}
                    >
                      {link.route}
                    </div>
                  );
                })}
              </Grid>
              <Grid sx={{ px: 3, mb: 2 }}>
                <Button
                  className="tnc-button"
                  onClick={() => navigate("/terms-conditions")}
                >
                  T&C
                </Button>
                <Button
                  className="privacy-policy"
                  onClick={() => navigate("/privacy-policy")}
                >
                  {" "}
                  Privacy Policy
                </Button>
              </Grid>
            </Box>
          </Drawer>
        </React.Fragment>
      </div>
    </ThemeProvider>
  );
};

export default SideDrawer;
