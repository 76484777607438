import { m } from "framer-motion";
// @mui
import { Box } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
//
import { varContainer } from "./variants/container";
import { useRef } from "react";

// ----------------------------------------------------------------------

export default function MotionViewport({
  children,
  disableAnimatedMobile = true,
  ...other
}) {
  const isMobile = useResponsive("down", "sm");
  const scrollRef = useRef(null);

  if (isMobile && disableAnimatedMobile) {
    return <Box {...other}>{children}</Box>;
  }

  return (
    <Box
      component={m.div}
      initial="initial"
      whileInView="animate"
      viewport={{ once: false, amount: 0.3, root: scrollRef }}
      variants={varContainer()}
      {...other}
    >
      {children}
    </Box>
  );
}
