import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import useResponsive from "../hooks/useResponsive";

const ScootyColors = ({ colors, view }) => {
  const isBig = useResponsive("up", "sm");
  const [selectedColor, setSelectedColor] = useState(
    view === "destinyPlus" ? "Blue" : "Black"
  );

  const selectScooty = (passedColor) => {
    const singleColor = colors.filter((item) => item.color === passedColor);
    return singleColor[0].scootyImg;
  };

  return (
    <Grid container sx={{ my: 12 }}>
      <Grid
        item
        md={6}
        xs={12}
        className="flex-col-hs-vc"
        sx={{ pl: 8, mb: { md: 0, xs: 6 } }}
      >
        <Typography
          sx={{ fontSize: "38px", fontWeight: "900", color: "#04184b" }}
        >
          Custom made. For you
        </Typography>
        <Typography
          sx={{ wordSpacing: "2px", fontWeight: "600", opacity: "0.6", my: 2 }}
        >
          Go glossy. Go full technicolour. Take your pick!
        </Typography>
        <Box>
          <Typography
            sx={{
              my: 2,
              color: "#04184b",
              fontWeight: "900",
              fontStyle: "italic",
              fontSize: "20px",
            }}
          >
            {selectedColor}
          </Typography>
          <div className="flex-r-hs" style={{ width: "100%" }}>
            {colors.map((item) => {
              return (
                <>
                  <img
                    src={item.colorImg}
                    alt={item.color}
                    width="8%"
                    className={
                      selectedColor === item.color
                        ? "scooty-selected scooty-colors-circle"
                        : "scooty-colors-circle"
                    }
                    onClick={() => setSelectedColor(item.color)}
                  />
                </>
              );
            })}
          </div>
        </Box>
      </Grid>
      <Grid item md={6} xs={12} className="flex-r-hc">
        <img
          src={selectScooty(selectedColor)}
          alt="scooty"
          width={isBig ? "70%" : "90%"}
          style={{ borderRadius: "8px" }}
        />
      </Grid>
    </Grid>
  );
};

export default ScootyColors;
