import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { ola1Img, ola2Img } from "../../Icons";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import MotionViewport from "../animate/MotionViewport";
import { varFade } from "../animate/variants/fade";

const FeaturesShowcase = ({ isBig }) => {
  const navigate = useNavigate();
  return (
    <MotionViewport
      variants={
        varFade({
          duration: 0.7,
        }).inUp
      }
    >
      <Box
        sx={{ display: "flex", justifyContent: "center", width: "100%", my: 6 }}
      >
        <Grid container sx={{ width: "80%" }}>
          <Grid item md={6} xs={12} sx={{ pr: 1.3 }}>
            <div className="img-hover-zoom">
              <img src={ola1Img} alt="scooty" width="100%" />
            </div>
            <div className="showcase-box">
              <Typography className="showcase-heading">
                <span>Destiny +</span>
              </Typography>
              {isBig ? (
                <section className="animation mt-3">
                  <div className="animText">
                    <div>Purchase window is now live!</div>
                  </div>
                  <div className="animText">
                    <div>Engineered to thrill</div>
                  </div>
                </section>
              ) : (
                <div className="animText">Engineered to thrill</div>
              )}

              {/* <Typography
              sx={{ color: "#04184b", fontWeight: "900", fontSize: "15px" }}
            >
              From
            </Typography>
            <Typography>
              <span style={{ color: "#04184b", fontWeight: "900" }}>₹</span>
              <span className="showcase-amount">1,47,499</span>
            </Typography> */}

              <Divider sx={{ my: 3.5, background: "#87a4e" }} />

              <div className="flex-r-hs-vc showcase-features">
                {" "}
                <Icon icon="ph:star-four-fill" className="showcase-icon" /> 250W
                Motor Power
              </div>
              <div className="flex-r-hs-vc showcase-features">
                {" "}
                <Icon icon="ph:star-four-fill" className="showcase-icon" />{" "}
                Smart wireless controller
              </div>
              <div className="flex-r-hs-vc showcase-features">
                {" "}
                <Icon icon="ph:star-four-fill" className="showcase-icon" /> 25
                kmph Top Speed
              </div>
            </div>
            <Button
              variant="contained"
              className="showcase-button"
              onClick={() => navigate("/scooty/destiny-plus")}
            >
              Explore Destiny +
            </Button>
          </Grid>
          {/*  */}
          <Grid item md={6} xs={12} sx={{ pl: 1.3 }}>
            <div className="img-hover-zoom">
              <img src={ola2Img} alt="scooty" width="100%" />
            </div>

            <div className="showcase-box1">
              <Typography className="showcase-heading1">
                <span>Phantom</span>
              </Typography>
              {isBig ? (
                <section className="animation mt-3">
                  <div className="animText1">
                    <div>Purchase window is now live!</div>
                  </div>
                  <div className="animText1">
                    <div>Engineered to thrill</div>
                  </div>
                </section>
              ) : (
                <div className="animText">Engineered to thrill</div>
              )}

              {/* <Typography
              sx={{ color: "#034624", fontWeight: "900", fontSize: "15px" }}
            >
              From
            </Typography>
            <Typography>
              <span style={{ color: "#034624", fontWeight: "900" }}>₹</span>
              <span className="showcase-amount1">1,47,499</span>
            </Typography> */}

              <Divider sx={{ my: 3.5, background: "#87a4e" }} />

              <div className="flex-r-hs-vc showcase-features1">
                {" "}
                <Icon
                  icon="ph:star-four-fill"
                  className="showcase-icon1"
                />{" "}
                250W Motor Power
              </div>
              <div className="flex-r-hs-vc showcase-features1">
                {" "}
                <Icon
                  icon="ph:star-four-fill"
                  className="showcase-icon1"
                />{" "}
                Smart wireless controller
              </div>
              <div className="flex-r-hs-vc showcase-features1">
                {" "}
                <Icon icon="ph:star-four-fill" className="showcase-icon1" /> 25
                kmph Top Speed
              </div>
            </div>
            <Button
              variant="contained"
              className="showcase-button1"
              onClick={() => navigate("/scooty/phantom")}
            >
              Explore Phantom
            </Button>
          </Grid>
        </Grid>
      </Box>
    </MotionViewport>
  );
};

export default FeaturesShowcase;
