import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
// import { logo_png } from "../iconsimport";
import { whiteLogo } from "../Icons";
import { Icon } from "@iconify/react";
import useResponsive from "../hooks/useResponsive";

const Footer = () => {
  const isMobile = useResponsive("down", "sm");
  const location = useLocation();

  let message = "Hello! Urban eBikes!"; // Default message for unknown routes

  // Determine the message based on the current route
  if (location.pathname === "/scooty/destiny-plus") {
    message =
      "Hello! Urban eBikes! I want to enquire about destiny plus model. Kindly send the details for the former..";
  } else if (location.pathname === "/scooty/phantom") {
    // eslint-disable-next-line no-unused-vars
    message =
      "Hello! Urban eBikes! I want to enquire about phantom model. Kindly send the details for the former..";
  } else {
  }
  const date = new Date().getFullYear();
  return (
    <>
      <Box
        sx={{
          height: "auto",
          px: 5,
          pb: 2,
          pt: 3,
        }}
        className="footer"
      >
        <Grid container>
          <Grid md={3} xs={12}>
            <div className="footer-logo">
              <img
                src={whiteLogo}
                alt="Urban eBikes Logo"
                width={isMobile ? "80" : "130"}
              />
              <Box sx={{ mt: 2 }}>
                {!isMobile && (
                  <Typography sx={{ color: "#fff", fontSize: "12px" }}>
                    <strong
                      style={{
                        fontSize: "12px",
                        marginBottom: "15px",
                        color: "#fff",
                        marginRight: "5px",
                      }}
                    >
                      CIN :
                    </strong>
                    <span>U36999DL2022PTC406337</span>
                  </Typography>
                )}
              </Box>
            </div>
          </Grid>
          <Grid
            md={3}
            sm={4}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#fff",
              mt: 3,
            }}
          >
            <strong style={{ fontSize: "18px", marginBottom: "15px" }}>
              Legal
            </strong>
            <Link
              style={{
                color: "#fff",
                fontSize: "15px",
                textDecoration: "none",
                marginBottom: "5px",
              }}
              to="privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Link>
            <Link
              target="_blank"
              style={{
                color: "#fff",
                fontSize: "15px",
                textDecoration: "none",
                marginBottom: "5px",
              }}
              to="terms-conditions"
            >
              Terms & Conditions
            </Link>
            <Link
              target="_blank"
              style={{
                color: "#fff",
                fontSize: "15px",
                textDecoration: "none",
                marginBottom: "5px",
              }}
              to="faqs"
            >
              FAQ's
            </Link>
          </Grid>
          <Grid
            md={3}
            sm={4}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#fff",
              mt: 3,
            }}
          >
            <strong style={{ fontSize: "18px", marginBottom: "15px" }}>
              Company
            </strong>

            <Link
              style={{
                color: "#fff",
                fontSize: "15px",
                textDecoration: "none",
                marginBottom: "5px",
              }}
              to="/about-us"
            >
              About Us
            </Link>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#fff",
              }}
            >
              <strong
                style={{
                  fontSize: "18px",
                  marginBottom: "15px",
                  marginTop: "10px",
                }}
              >
                Top Models
              </strong>
              <Link
                style={{
                  color: "#fff",
                  fontSize: "15px",
                  textDecoration: "none",
                  marginBottom: "5px",
                  mt: 3,
                }}
                to="/scooty/destiny-plus"
              >
                Destiny+
              </Link>
              <Link
                style={{
                  color: "#fff",
                  fontSize: "15px",
                  textDecoration: "none",
                  marginBottom: "5px",
                }}
                to="scooty/phantom"
              >
                Phantom
              </Link>
            </Stack>
          </Grid>
          <Grid
            md={3}
            sm={4}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#fff",
            }}
          >
            <div>
              <p style={{ fontSize: "18px", color: "#fff" }}>
                Have a support question?
              </p>

              <Box>
                <Link
                  to="/contact-us"
                  // to="https://wa.me/9530612345?text=I'm%20interested%20in%20BiggBrains"
                  style={{
                    borderRadius: "50px",
                    background: "#0493D4",
                    padding: "8px",
                    color: "#000",
                    textDecoration: "none",
                  }}
                  target="_blank"
                >
                  Contact us
                </Link>

                <Box sx={{ mt: 5 }}>
                  <Icon
                    icon="ic:baseline-call"
                    className="just-hover"
                    style={{ marginRight: "15px" }}
                  />

                  <span style={{ color: "#fff", fontSize: "15px" }}>
                    +91-9530612345
                  </span>
                </Box>
                <div>
                  <a
                    className="footer-call-to-action-link"
                    href="mailto:connect@biggbrains.com"
                    target="_self"
                    style={{ color: "#fff" }}
                  >
                    <Icon
                      icon="ic:round-mail"
                      className="just-hover"
                      style={{ marginRight: "15px" }}
                    />
                    <span
                      style={{
                        color: "#fff",
                        fontSize: "15px",
                        textDecoration: "none",
                      }}
                      href="mailto"
                    >
                      info@urbanebikes.in
                    </span>
                  </a>
                </div>
              </Box>
            </div>
          </Grid>
          {isMobile && (
            <Grid xs={12} sx={{ mt: 4, mb: 3 }}>
              <Typography sx={{ color: "#fff", fontSize: "12px" }}>
                <strong
                  style={{
                    fontSize: "12px",
                    marginBottom: "15px",
                    color: "#fff",
                    marginRight: "5px",
                  }}
                >
                  CIN :
                </strong>
                <span>U36999DL2022PTC406337</span>
              </Typography>
            </Grid>
          )}
        </Grid>
        <Box
          sx={{
            textAlign: "center",
            mt: 5,
            color: "#fff",
            fontSize: "13px",
          }}
        >
          © Copyright {date} Urban eBikes Pvt Ltd. All Rights Reserve
        </Box>
      </Box>
    </>
  );
};

export default Footer;
