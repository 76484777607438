import { Container, Typography } from "@mui/material";
import React from "react";
import Banner from "./Banner";
import { gradientBg } from "../Icons";

const PrivacyPolicy = () => {
  return (
    <>
      <Banner
        img={gradientBg}
        title=" Privacy Policy"
        subTitle="Urban eBikes"
      />
      <Container sx={{ mb: 10 }}>
        <h2>1. Introduction</h2>

        <p>
          This Privacy Policy outlines the policies and practices of Urban
          e-Bikes ("we," "our," or "us") regarding the collection, use, and
          protection of personal information obtained from users of the Urban
          e-Bikes Electric Vehicle Website (the "Website"). By accessing and
          using the Website, you consent to the terms outlined in this Privacy
          Policy.
        </p>
        <h2>2. Information We Collect</h2>
        <p>
          2.1. Personal Information: We may collect personal information, such
          as your name, email address, phone number, and location, when you
          voluntarily provide it through forms, inquiries, or account
          registration on the Website.
        </p>
        <p>
          2.2. Usage Data: We may collect non-personal information about your
          interactions with the Website, including IP addresses, browser type,
          operating system, referral URLs, pages viewed, and timestamps. We use
          this data for analytical purposes and to improve the Website.
        </p>
        <h2>3. How We Use Your Information</h2>
        <p>We may use your information for the following purposes:</p>
        <p>3.1. To provide and maintain the Website's functionality.</p>
        <p>
          3.2. To respond to inquiries, requests, or comments submitted through
          the Website.
        </p>
        <p>
          3.3. To send periodic emails or newsletters related to our products,
          services, and updates (you may opt-out at any time).
        </p>
        <p>
          3.4. To analyse user behavior and improve the Website's content and
          user experience.
        </p>
        <h2>4. Sharing Your Information</h2>
        <p>
          We do not sell, trade, or rent your personal information to third
          parties. However, we may share your information with trusted
          third-party service providers who assist us in operating the Website
          and conducting our business. These service providers are contractually
          obligated to protect your information and may only use it for
          authorized purposes.
        </p>
        <h2>5. Cookies and Tracking Technologies</h2>
        <p>
          We use cookies and similar tracking technologies to enhance your
          experience on the Website. You may choose to disable cookies through
          your web browser settings, but doing so may affect certain features of
          the Website.
        </p>
        <h2>6. Data Security</h2>
        <p>
          We employ reasonable security measures to protect your information.
          However, no method of transmission over the internet or electronic
          storage is entirely secure, and we cannot guarantee the absolute
          security of your data.
        </p>
        <h2>7. Your Choices</h2>
        <p>
          You may access, update, or delete your personal information by
          contacting us at info@urbanebikes.in. You may also unsubscribe from
          our email communications by following the instructions provided in the
          emails.
        </p>
        <h2>8. Children's Privacy</h2>
        <p>
          The Website is not intended for users under the age of 13. We do not
          knowingly collect or maintain personal information from children under
          13. If we become aware that we have collected personal information
          from a child under 13, we will take steps to delete it promptly.
        </p>
        <h2>9. Changes to this Privacy Policy</h2>
        <p>
          We reserve the right to update or revise this Privacy Policy at any
          time. We will indicate the "Last Updated" date at the beginning of
          this Privacy Policy. Your continued use of the Website after any
          changes to this Privacy Policy constitutes your acceptance of the
          revised terms.
        </p>
        <h2>10. Contact Us</h2>
        <p>
          If you have questions or concerns about this Privacy Policy or our
          data practices, please contact us at info@urbanebikes.in.
        </p>
        <p>
          **By using the Urban e-Bikes Electric Vehicle Website, you acknowledge
          that you have read, understood, and agree to the terms outlined in
          this Privacy Policy.
        </p>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
