import React from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { chatImg } from "../Icons";

const ChatBox = () => {
  const location = useLocation();

  let message = "Hello! Urban eBikes!"; // Default message for unknown routes

  // Determine the message based on the current route
  if (location.pathname === "/scooty/destiny-plus") {
    message =
      "Hello! Urban eBikes! I want to enquire about destiny plus model. Kindly send the details for the former..";
  } else if (location.pathname === "/scooty/phantom") {
    message =
      "Hello! Urban eBikes! I want to enquire about phantom model. Kindly send the details for the former..";
  }

  const openWhatsAppChat = () => {
    const phoneNumber = "9530612345"; // Replace with the phone number you want to open the chat with

    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;

    // Open the WhatsApp chat in a new window or tab
    window.open(whatsappURL, "_blank");
  };

  return (
    <div>
      <Box onClick={openWhatsAppChat}>
        <img
          src={chatImg}
          type="button"
          className="open-button"
          alt="chat box"
          // onClick={() => {
          //   //openForm();
          //   navigate("whatsapp://send?abid=8745095350&text=Hello%2C%20World!");
          // }}
          width="60px"
        />
      </Box>
    </div>
  );
};

export default ChatBox;
