import gear from "./assets/icons/build_icon.webp";
import mobileUse from "./assets/icons/connected-icon.webp";
import network from "./assets/icons/Network.webp";
import transmission from "./assets/icons/transmission.webp";
import banner1 from "./assets/banner/banner-1.jpg";
import banner2 from "./assets/banner/banner-2.jpg";
import banner3 from "./assets/banner/banner-3.jpg";
import banner4 from "./assets/banner/banner-4.jpg";
import banner5 from "./assets/banner/banner-5.jpg";
import bannerMain from "./assets//banner/destiny-plus.webp";
import ola1 from "./assets/banner/destiny-plus-scooty.webp";
import ola2 from "./assets/banner/phantom.webp";
import user from "./assets/icons/default-user.jpg";
import logo_icon from "../src/assets/icons/logo.png";
import review_star from "../src/assets/icons/stars.webp";
import locBankground from "./assets/backgrounds/locationimagee.webp";
import locscooty from "./assets/scootys/location-scooty.webp";
import aboutusbanner from "../src/assets/backgrounds/about_us_banner.webp";
import white_logo from "./assets/icons/whiteLogo.png";
import gradient_bg from "../src/assets/backgrounds/gradientBg.png";
import savings from "./assets/banner/savings.webp";
import bike_Girl from "../src/assets/bikes/bike_girl.png";
import future_ebike from "../src/assets/bikes/future_eBike.webp";
import green_arrow from "../src/assets/icons/green_arrow.webp";
import destinybanner from "./assets/scootys/destiny/destiny-banner.webp";
import destinySmBanner1 from "./assets/scootys/destiny/DestinyMobile banner_1.webp";
import destinySmBanner2 from "./assets/scootys/destiny/DestinyMobile banner_2.webp";
import destinySmBanner3 from "./assets/scootys/destiny/DestinyMobile banner_3.webp";
import destinySmBanner4 from "./assets/scootys/destiny/DestinyMobile banner_4.webp";
import destinySmBanner5 from "./assets/scootys/destiny/DestinyMobile banner_5.webp";
import destinySmBanner6 from "./assets/scootys/destiny/DestinyMobile banner_6.webp";
import destiny1 from "./assets/scootys/destiny/destiny1.webp";
import destiny2 from "./assets/scootys/destiny/destiny2.webp";
import destiny3 from "./assets/scootys/destiny/destiny3.webp";
import destiny4 from "./assets/scootys/destiny/destiny4.webp";
import destiny5 from "./assets/scootys/destiny/destiny5.webp";
import destiny6 from "./assets/scootys/destiny/destiny6.webp";
import destiny7 from "./assets/scootys/destiny/destiny7.webp";
import destiny8 from "./assets/scootys/destiny/destiny8.webp";
import collage_banner from "./assets/banner/collage_banner5.png";
import destinyblue from "./assets/scootys/destiny/destinyBlueColor.png";
import destinyblack from "./assets/scootys/destiny/destinyBlackColor.png";
import destinywhite from "./assets/scootys/destiny/destinyWhiteColor.png";
import destinyred from "./assets/scootys/destiny/destinyRedColor.png";
import destinysilver from "./assets/scootys/destiny/destinySilverColor.png";
import desPerformance1 from "./assets/scootys/destiny/performance1.png";
import desPerformance2 from "./assets/scootys/destiny/performance2.png";
import desPerformance3 from "./assets/scootys/destiny/performance3.png";
import desDesign1 from "./assets/scootys/destiny/design1.png";
import desDesign2 from "./assets/scootys/destiny/design2.png";
import desDesign3 from "./assets/scootys/destiny/design3.png";
import destinySavings from "./assets/scootys/destiny/destiny-savings.webp";
import phantomSavings from "./assets/scootys/phantom/phantom-savings.webp";
import phantomFeatures from "./assets/scootys/phantom/phantom-features.webp";
import phantomBanner from "./assets/scootys/phantom/phantom-banner.webp";
import phantomColor from "./assets/scootys/phantom/phantomGrey.svg";
import phantom1 from "./assets/scootys/phantom/phantom1.webp";
import phantom2 from "./assets/scootys/phantom/phantom2.webp";
import phantom3 from "./assets/scootys/phantom/phantom3.webp";
import phantom4 from "./assets/scootys/phantom/phantom4.webp";
import phantom5 from "./assets/scootys/phantom/phantom5.webp";
import phantom6 from "./assets/scootys/phantom/phantom6.webp";
import phantom7 from "./assets/scootys/phantom/phantom7.webp";
import phantom8 from "./assets/scootys/phantom/phantom8.webp";
import phantomSmBanner1 from "./assets/scootys/phantom/Phantom Mobile banner_1.webp";
import phantomSmBanner2 from "./assets/scootys/phantom/Phantom Mobile banner_2.webp";
import phantomSmBanner3 from "./assets/scootys/phantom/Phantom Mobile banner_3.webp";
import phantomSmBanner4 from "./assets/scootys/phantom/Phantom Mobile banner_4.webp";
import phantomSmBanner5 from "./assets/scootys/phantom/Phantom Mobile banner_5.webp";
import phantomSmBanner6 from "./assets/scootys/phantom/Phantom Mobile banner_6.webp";
import chat_img from "../src/assets/icons/chat.webp";
import wp_img from "../src/assets/icons/wpLogo.png";
import freedomSmBanner1 from "./assets/scootys/freedom_pro/freedom pro Mobile banner_1.webp";
import freedomSmBanner2 from "./assets/scootys/freedom_pro/freedom pro Mobile banner_2.webp";
import freedomSmBanner3 from "./assets/scootys/freedom_pro/freedom pro Mobile banner_3.webp";
import freedomSmBanner4 from "./assets/scootys/freedom_pro/freedom pro Mobile banner_4.webp";
import freedomSmBanner5 from "./assets/scootys/freedom_pro/freedom pro Mobile banner_5.webp";
import freedomSmBanner6 from "./assets/scootys/freedom_pro/freedom pro Mobile banner_6.webp";
import freedomBanner from "./assets/scootys/freedom_pro/freedom pro.webp";
import freedomFeatures from "./assets/scootys/freedom_pro/freedom-features.webp";
import freedomProSavings from "./assets/scootys//freedom_pro/freedom-pro-savings.webp";
import freedom1 from "./assets/scootys/freedom_pro/Freedom Pro Black.webp";
import freedom2 from "./assets/scootys/freedom_pro/Freedom Pro Grey.webp";
import freedom3 from "./assets/scootys/freedom_pro/Freedom Pro blue.webp";
import freedom4 from "./assets/scootys/freedom_pro/Freedom Pro white.webp";

// exports
export const gearIcon = gear;
export const mobileIcon = mobileUse;
export const networkIcon = network;
export const transmissionIcon = transmission;
export const banner1Img = banner1;
export const banner2Img = banner2;
export const banner3Img = banner3;
export const banner4Img = banner4;
export const banner5Img = banner5;
export const bannerMainImg = bannerMain;
export const ola1Img = ola1;
export const ola2Img = ola2;
export const defaultUser = user;
export const logo = logo_icon;
export const whiteLogo = white_logo;
export const reviewStar = review_star;
export const locBankGroundImg = locBankground;
export const locScootyImg = locscooty;

export const aboutUsBanner = aboutusbanner;
export const gradientBg = gradient_bg;
export const savingsImg = savings;
export const BikeGirl = bike_Girl;
export const FutureEbike = future_ebike;
export const GreenArrow = green_arrow;
export const DestinyBannerImg = destinybanner;
export const DestinySmBanner1Img = destinySmBanner1;
export const DestinySmBanner2Img = destinySmBanner2;
export const DestinySmBanner3Img = destinySmBanner3;
export const DestinySmBanner4Img = destinySmBanner4;
export const DestinySmBanner5Img = destinySmBanner5;
export const DestinySmBanner6Img = destinySmBanner6;
export const Destiny1Img = destiny1;
export const Destiny2Img = destiny2;
export const Destiny3Img = destiny3;
export const Destiny4Img = destiny4;
export const Destiny5Img = destiny5;
export const Destiny6Img = destiny6;
export const Destiny7Img = destiny7;
export const Destiny8Img = destiny8;
export const CollageBanner = collage_banner;
export const DestinySilverImg = destinysilver;
export const DestinywhiteImg = destinywhite;
export const DestinyredImg = destinyred;
export const DestinyblueImg = destinyblue;
export const DestinyblackImg = destinyblack;
export const PhantomgreyImg = phantomColor;
export const desPerformance1Img = desPerformance1;
export const desPerformance2Img = desPerformance2;
export const desPerformance3Img = desPerformance3;
export const desDesign1Img = desDesign1;
export const desDesign2Img = desDesign2;
export const desDesign3Img = desDesign3;
export const destinySavingsImg = destinySavings;
export const phantomSavingsImg = phantomSavings;
export const phantomFeaturesImg = phantomFeatures;
export const phantomBannerImg = phantomBanner;
export const phantom1Img = phantom1;
export const phantom2Img = phantom2;
export const phantom3Img = phantom3;
export const phantom4Img = phantom4;
export const phantom5Img = phantom5;
export const phantom6Img = phantom6;
export const phantom7Img = phantom7;
export const phantom8Img = phantom8;
export const PhantomSmBanner1Img = phantomSmBanner1;
export const PhantomSmBanner2Img = phantomSmBanner2;
export const PhantomSmBanner3Img = phantomSmBanner3;
export const PhantomSmBanner4Img = phantomSmBanner4;
export const PhantomSmBanner5Img = phantomSmBanner5;
export const PhantomSmBanner6Img = phantomSmBanner6;
export const chatImg = chat_img;
export const FreedomSmBanner1Img = freedomSmBanner1;
export const FreedomSmBanner2Img = freedomSmBanner2;
export const FreedomSmBanner3Img = freedomSmBanner3;
export const FreedomSmBanner4Img = freedomSmBanner4;
export const FreedomSmBanner5Img = freedomSmBanner5;
export const FreedomSmBanner6Img = freedomSmBanner6;
export const freedomBannerImg = freedomBanner;
export const freedomFeaturesImg = freedomFeatures;
export const feedomProSavingsImg = freedomProSavings;
export const freedom1Img = freedom1;
export const freedom2Img = freedom2;
export const freedom3Img = freedom3;
export const freedom4Img = freedom4;

export const whatsapp = wp_img;
