import { Box } from "@mui/material";
import React, { useEffect } from "react";
import SingleScootyPageView from "../components/SingleScootyPageView";
import { Phantom } from "../utils/DataUtil";
import useResponsive from "../hooks/useResponsive";

const PhantomView = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const isBig = useResponsive("up", "sm");

  return (
    <Box>
      <SingleScootyPageView scooty={Phantom} view="phantom" isBig={isBig} />
    </Box>
  );
};

export default PhantomView;
