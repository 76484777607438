import { Icon } from "@iconify/react";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MotionViewport from "../animate/MotionViewport";
import { varFade } from "../animate/variants/fade";

const LocationComponent = ({
  img,
  maintext,
  firstSubText,
  secSubText,
  subImg,
  officeAddres,
  email,
  tel,
}) => {
  return (
    <Box
      className="position-relative"
      sx={{ height: { md: "900px", sm: "auto", xs: "auto" } }}
    >
      <img src={img} alt="images" width="100%" />
      <MotionViewport variants={varFade().in}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: { md: "absolute", sm: "none", xs: "none" },
            bottom: { md: "3%", sm: "none", xs: "none" },
          }}
        >
          <Grid
            container
            className="white-card-background"
            sx={{
              width: { md: "80%", sm: "100%", xs: "100%" },
              height: { md: "500px", sm: "auto", xs: "auto" },
              px: { md: 13, sm: 0, xs: 0 },
              py: 8,
              justifyContent: "center",
            }}
          >
            <Grid item md={7}>
              <Typography sx={{ fontSize: "20px" }}>{firstSubText}</Typography>
              <Typography
                sx={{ fontSize: "38px", fontWeight: "500", width: "95%" }}
              >
                {maintext}
              </Typography>
              <Box
                sx={{
                  width: { md: "60%", sm: "100%", xs: "100%" },
                  marginTop: "3rem",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="attributes-image-colored">
                  <Icon
                    icon="ion:location-sharp"
                    style={{ fontSize: "25px" }}
                  />
                </div>
                <Typography sx={{ fontSize: "30px", fontWeight: "500", my: 2 }}>
                  Our Office Location
                </Typography>
                <Typography sx={{ fontSize: "14px", width: "80%" }}>
                  {officeAddres}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              md={5}
              className="flex-col-hc-vs"
              sx={{ mt: { md: 0, sm: 3, xs: 3 } }}
            >
              <div
                style={{
                  width: "250px",
                  height: "251px",
                  marginBottom: "15px",
                }}
              >
                <img
                  src={subImg}
                  alt="subimg"
                  width="100%"
                  style={{ borderRadius: "8px" }}
                />
              </div>
              <Typography
                sx={{ fontSize: "16px", marginBottom: "20px" }}
                className="flex-r-hc-vc "
              >
                <Icon
                  icon="carbon:email"
                  style={{ marginRight: "12px", fontSize: "25px" }}
                />{" "}
                {email}
              </Typography>
              <Typography sx={{ fontSize: "16px" }} className="flex-r-hc-vc ">
                <Icon
                  icon="clarity:mobile-solid"
                  style={{ marginRight: "12px", fontSize: "25px" }}
                />{" "}
                {tel}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </MotionViewport>
    </Box>
  );
};

export default LocationComponent;
