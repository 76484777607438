import { Box, Grid } from "@mui/material";
import React from "react";
import { FIRSTNAV, SECNAV } from "../utils/constants";
import SideDrawer from "./SideDrawer";
import { whiteLogo } from "../Icons";
import { Link } from "react-router-dom";
import useResponsive from "../hooks/useResponsive";

const Navbar = () => {
  const isBig = useResponsive("up", "sm");
  return (
    <Grid container className="navbar">
      <Grid item md={6} className="flex-r-hs-vc">
        {/* logo */}
        <Link to="/">
          <img src={whiteLogo} alt="whiteLogo" className="nav-links-logo" />
        </Link>

        {/* scootys */}
        <Box sx={{ mx: 5, display: isBig ? "block" : "none" }}>
          {FIRSTNAV.map((item, index) => {
            const { route, to } = item;
            return (
              <Link key={index} className="nav-links" to={to}>
                {route}
              </Link>
            );
          })}
        </Box>
      </Grid>
      <Grid md={6} className="flex-r-he-vc">
        <Box sx={{ display: isBig ? "block" : "none" }}>
          {SECNAV.map((item, index) => {
            const { route, to } = item;
            return (
              <Link key={index} className="nav-links" to={to}>
                {route}
              </Link>
            );
          })}
        </Box>
        {!isBig && <SideDrawer />}
      </Grid>
    </Grid>
  );
};

export default Navbar;
