import React, { useEffect } from "react";
import Banner from "../components/Banner";
import {
  BikeGirl,
  CollageBanner,
  FutureEbike,
  GreenArrow,
  aboutUsBanner,
} from "../Icons";
import { Box, Container, Grid, Typography } from "@mui/material";
import { varFade } from "../components/animate/variants/fade";
import MotionViewport from "../components/animate/MotionViewport";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Banner
        img={aboutUsBanner}
        subTitle="Powered by Electricity, Driven by Dreams"
        alt="about-us-banner"
        isNeon
        title="About Us"
      />
      <Container>
        <Section1 />
      </Container>
    </>
  );
};

export default AboutUs;

function Section1() {
  return (
    <>
      <Grid>
        <div>
          <p>
            <MotionViewport variants={varFade().inRight}>
              <span className="p-heading">
                <strong>Scoot Smart, Scoot Electric</strong>
              </span>
            </MotionViewport>
          </p>
          <MotionViewport variants={varFade().inRight}>
            <div className="description">
              <p>
                <span style={{ fontSize: "20px" }}>
                  Urban eBikes, an Electric Two-Wheeler manufacturer, was
                  incorporated in October 2021 with the aim to deliver quality
                  products at affordable prices. Urban eBikes can be broadly
                  considered as - “A Sustainable and Convenient Mode of Urban
                  Transportation”.
                </span>
              </p>
            </div>
          </MotionViewport>
        </div>

        {/* ######################## */}
        {/* collage ################ */}
        {/* ######################## */}
        <div>
          <MotionViewport
            variants={
              varFade({
                distance: -200,
                durationIn: 0.5,
                durationOut: 0.3,
                easeIn: "easeInOut",
                easeOut: "easeOut",
              }).inUp
            }
          >
            <img
              className="mgz-hover-main"
              src={CollageBanner}
              alt="over_Lap_Img_0b3f00b159_1_"
              title=""
              style={{
                width: "100%",
                // marginTop: "-60px",
              }}
            />
          </MotionViewport>
        </div>
      </Grid>

      <Grid
        container
        spacing={4}
        sx={{
          ...gridMargin,
        }}
      >
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              ...boxBackground,
              ...boxPadding,
            }}
          >
            <MotionViewport variants={varFade().inRight}>
              <span>
                <Typography
                  component="p"
                  sx={{
                    width: { xs: "100%", md: "80%" },
                  }}
                >
                  <span className="p-heading">
                    <strong>Zip, Zap Zero Emission</strong>
                  </span>
                </Typography>
              </span>
              <div
                className="description-italics"
                style={{
                  width: "100%",
                }}
              >
                <p>
                  <span>
                    Our aim is to focus on being technology driven and
                    delivering the utmost quality of product. Urban eBikes has
                    its manufacturing units in Bathinda (Punjab) & Delhi, with
                    quality equipment and machinery. We have an R & D Department
                    with qualified technical staff with core expertise in the
                    domain.
                  </span>
                </p>
              </div>
              <div className="mission_electric_info">
                <p>
                  <span>
                    With&nbsp;
                    <span style={{ color: "#16aa51" }}>Urban Electric</span>
                    &nbsp;our vision is to reduce global emissions and fossil
                    fuel dependency by making India the epic centre of
                    electrification.They are energy-efficient and produce fewer
                    emissions than cars, making them a key element in reducing
                    the carbon footprint of urban transportation.
                  </span>
                </p>
              </div>
            </MotionViewport>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <MotionViewport variants={varFade().inLeft}>
            <div
              className="description"
              style={{
                width: "100%",
                marginTop: "0px",
              }}
            >
              <p>
                <span>
                  At present, we have launched six low-speed Electric Scooter
                  models and we are shortly coming up with a range of High-Speed
                  Electric Scooters and Motorbikes. The unique selling
                  proposition of Urban eBikes is Quality & Affordability. We
                  have a network of 100+ dealers at present spread majorly
                  across the states of Punjab, Haryana, Delhi, UP, Gujarat, West
                  Bengal & Kerala along with continuous expansion across India.
                </span>
              </p>
            </div>
            <div>
              <img
                src={BikeGirl}
                alt="bike_man"
                style={{
                  width: "100%",
                }}
              />
            </div>
          </MotionViewport>
        </Grid>
      </Grid>
      {/* ###################################### */}
      {/* FUTURE OF OLA ELECTRIC ############### */}
      {/* ###################################### */}
      <MotionViewport
        variants={
          varFade({
            distance: 200,
            durationIn: 0.5,
            durationOut: 0.3,
            easeIn: "easeInOut",
            easeOut: "easeOut",
          }).inDown
        }
      >
        <Grid
          container
          spacing={4}
          sx={{
            ...gridMargin,
          }}
        >
          <Grid item md={6} xs={12}>
            <Typography
              component="p"
              sx={{
                width: { xs: "100%", md: "80%" },
              }}
            >
              <span className="p-heading">
                <strong>The future of Urban eBikes</strong>
              </span>
            </Typography>
            <div className="future_content_listing">
              <ul
                className="description"
                style={{
                  width: "100%",
                }}
              >
                {future_listing.map((item, index) => (
                  <li key={index}>
                    <span>
                      <img src={GreenArrow} alt="" width="11" height="16" />
                    </span>{" "}
                    {item.li}
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              sx={{
                ...boxBackground,
                ...boxPadding,
              }}
            >
              <div
                className="description-italics"
                style={{
                  width: "100%",
                }}
              >
                <p>
                  <span>
                    For this to become a reality, we aim to not just make
                    electric vehicles but engineer a change in the mindsets of
                    every Indian, a change that powers the vision behind
                    Mission Electric.
                  </span>
                </p>
              </div>
              <div>
                <img
                  src={FutureEbike}
                  alt="future_ebike"
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </MotionViewport>
    </>
  );
}
const gridMargin = {
  mt: 8,
};
const boxPadding = {
  px: "12px",
};
const boxBackground = {
  background: "#FFFACD",
};

const future_listing = [
  {
    li: `We are focused on being technology driven and never compromise
        with the quality of product.`,
  },
  {
    li: `High quality spare and lot of feature at affordable price.`,
  },
  {
    li: `Both Low speed (25Kms/hr) & High speed electric models.`,
  },
];
