import { Box } from "@mui/material";
import React from "react";
import Navbar from "../components/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import ChatBox from "../components/ChatBox";

const SharedLayout = () => {
  return (
    <Box>
      <Navbar />
      <Outlet />
      <ChatBox />
      <Footer />
    </Box>
  );
};

export default SharedLayout;
