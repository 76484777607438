import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ReviewsCard from "./ReviewsCard";
import useResponsive from "../../hooks/useResponsive";

const Reviews = () => {
  const isMobile = useResponsive("down", "sm");

  return (
    <>
      <Grid container className="reviews1" sx={{ mt: 11, py: 5 }}>
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: isMobile ? "30px" : "60px",
              fontWeight: "900",
              color: "#fff",
              textAlign: "center",
            }}
          >
            HAPPY CUSTOMERS
          </Typography>
          <Typography
            sx={{ color: "#fff", fontSize: "20px", textAlign: "center" }}
          >
            Ride the future go electric
          </Typography>
        </Grid>
        <Box className="flex-r-hc" sx={{ width: "100%" }}>
          <Grid container sx={{ width: "90%" }}>
            <Grid item lg={3.8} md={3.8} sm={12} xs={12} sx={{ py: 2 }}>
              <ReviewsCard
                title="Rishabh"
                reviews="I recently purchased a motorcycle from this website, and the whole experience was fantastic. The website's user-friendly interface made it easy to browse and compare different models. The detailed descriptions and specifications helped me make an informed decision. The bike was delivered on time, and it was in perfect condition. I'm loving every ride on my new two-wheeler. Highly recommend this website for all your two-wheeler needs!"
              />
            </Grid>
            <Grid item lg={3.8} md={3.8} sm={12} xs={12} sx={{ py: 2 }}>
              <ReviewsCard
                title="Payal"
                reviews="I bought a scooter for my daily commute, and I couldn't be happier. The website had a good selection of scooters, and I found one that perfectly suits my needs. The ordering process was straightforward, and the delivery was prompt. The only reason I'm not giving it five stars is that I had a minor issue with the scooter's mirrors, but the customer support team was quick to resolve it. Overall, a great experience!              "
              />
            </Grid>
            <Grid item lg={3.8} md={3.8} sm={12} xs={12} sx={{ py: 2 }}>
              <ReviewsCard
                title="Rupa"
                reviews="I needed a scooter for my daughter, and I found just the right one on this website. The prices were reasonable, and the ordering process was straightforward. The scooter arrived on time and in good condition. My daughter is thrilled with her new ride. The only reason I'm not giving five stars is that I wish there were more color options to choose from. Nonetheless, a solid experience overall.              "
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default Reviews;
