import React, { useEffect } from "react";
import SingleScootyPageView from "../components/SingleScootyPageView";
import { Destiny } from "../utils/DataUtil";
import useResponsive from "../hooks/useResponsive";

const DestinyPlusView = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const isBig = useResponsive("up", "sm");
  return (
    <SingleScootyPageView scooty={Destiny} view="destinyPlus" isBig={isBig} />
  );
};

export default DestinyPlusView;
