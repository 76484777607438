import React, { useState, useRef } from "react";
import ReactPlayer from "react-player/youtube";
import MotionViewport from "./animate/MotionViewport";
import { varFade } from "./animate/variants/fade";
import useResponsive from "../hooks/useResponsive";

function CustomVideoPlayer({
  url = "https://www.youtube.com/embed/gHFUF52wAEI?si=3g9SdJ5vQjPoyObk",
}) {
  const [isPlaying, setIsPlaying] = useState(true);
  const isMobile = useResponsive("down", "sm");
  const playerRef = useRef(null);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      playerRef.current.getInternalPlayer().pauseVideo(); // Pause the video
    } else {
      playerRef.current.getInternalPlayer().playVideo(); // Play the video
    }
  };

  return (
    <MotionViewport variants={varFade().in}>
      <div className={isMobile ? "video-container-mobile" : "video-container"}>
        <button
          className={`my-ytp-large-play-button ${isPlaying ? "hidden" : ""}`}
          onClick={togglePlayPause}
          aria-label="Play"
        >
          <YoutubeButton />
        </button>
        <ReactPlayer
          ref={playerRef}
          className={isMobile ? "react-player-mobile" : ""}
          muted
          controls
          url={url}
          width="100%"
          height={isMobile ? "100%" : "90vh"}
          playing={isPlaying}
          onPause={() => setIsPlaying(false)}
          onPlay={() => setIsPlaying(true)}
        />
      </div>
    </MotionViewport>
  );
}

export default CustomVideoPlayer;
function YoutubeButton() {
  return (
    <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
      <path
        d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
        fill="#f00"
      ></path>
      <path d="M 45,24 27,14 27,34" fill="#fff"></path>
    </svg>
  );
}
