import { Grid } from "@mui/material";
import React from "react";

const SavingsSection = ({ img }) => {
  return (
    <Grid container sx={{ mt: 3, mb: -1 }}>
      <Grid item md={12} xs={12}>
        <img src={img} alt="savings" width="100%" />
      </Grid>
    </Grid>
  );
};

export default SavingsSection;
